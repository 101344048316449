.container-doc {
  margin-top: 20px;
}
.tabs {
  .tab-section {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
  }
  /* & .Mui-selected {
    background-color: "#131C4C !important";
    color: "white";
    &:hover {
      background-color: "#131C4C !important";
    }
  } */
}
.map-marker {
  position: relative;
  cursor: pointer;
}
.iot-dark {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #131c4c;
}
.CardContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.progress {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #131c4c;
}
.Active {
  font-size: 20px;
  font-weight: 600;

  letter-spacing: 0px;
  color: #13ba21;
}
.superadmin {
  text-align: left;
  font-weight: 500;
  letter-spacing: 0px;
  color: #131c4c;
}
.welcome-details {
  text-align: left;
  font-weight: 300;
  letter-spacing: 0px;
  color: #131c4c;
}
.marker-title {
  background: #131c4c 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  width: 80px;
  height: 30px;
  padding: 10px;
  .title {
    font-weight: 600;
    font: normal normal medium 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 10px !important;
  }
}

.marker-description {
  padding: 10px;
  border-radius: 100px;
  background: #131c4c 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #0000001c;
  border: 1px solid #bcc0d5;
  border-radius: 4px;
  opacity: 1;
  width: 200px;
  height: auto;
  color: #ffffff;
}
.status {
  color: #d0be39;
}
.details {
  color: #8a8ea7;
}
& .MuiTabs-flexContainer {
  gap: 10px !important;
}
.tab-order {
  color: white;
}
.main-tab {
  display: flex;
  padding: 10px;

  & .Mui-selected {
    background-color: "white";
    color: "#131C4C !important";
    &:hover {
      background-color: "#131C4C !important";
    }
  }
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  background: #bcc0d5;
  margin-top: 20px;
  padding-left: 8px;
  padding-top: 2px;
  font-size: 12px;
}
.pLoc{
  display: block;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}
.flexed {
  display: flex;
  gap: 10px;
}
.card-all {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.flexed-pass {
  display: flex;
  gap: 10px;
  flex-direction: row;
  padding-bottom: 20px;
}
.btns {
  display: flex;
  justify-content: space-between;
  translate: (0, 50);
}
.tab-container {
  margin-top: 20px;
  .tab-header {
    background-color: #131c4c;

    border-radius: 17px 17px 0px 0px;
  }
  .tab-content {
    background-color: #f9f9f9;
    padding: 20px 20px 20px 20px;
    height: 100%;
    width: 100%;
    .heading {
      text-align: left;
      font: normal normal bold 24px/62px Montserrat;
      letter-spacing: 0px;
      color: #131c4c;
      opacity: 1;
    }
    .item {
      text-align: left;
      font: normal normal bold 20px/62px Montserrat;
      letter-spacing: 0px;
      color: #131c4c;
      opacity: 1;
    }
    .bag-info {
      text-align: left;
      font: normal normal 600 15px/19px Montserrat;
      letter-spacing: 0px;
      color: #131c4c;
      opacity: 1;
    }
    .bag-details {
      text-align: left;
      font: normal normal 600 15px/19px Montserrat;
      letter-spacing: 0px;
      color: #131c4c;
      opacity: 1;
    }
  }
  .Card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.1);
    width: 370px;
    height: 260px;
    margin: 10px;
    cursor: pointer;
  }
  .card-all {
    overflow-y: scroll;
  }
  .card-details {
    display: flex;
    justify-content: space-between;
  }
  .overlay {
    position: absolute;
    background-color: rgba(
      255,
      0,
      0,
      0.5
    ); /* Example background color with transparency */
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translate(50%, 100%);
    height: 1080px;
    width: max-content;
  }
  .card-doc-text {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #131c4c;
    opacity: 1;
  }
  .card-doc-per {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #131c4c;
    opacity: 1;
  }
  .pName {
    color: #131c4c;
    font-size: 16px;
    font-weight: 600;
  }
  .pDept {
    color: #131c4c;
    font-size: 14px;
    font-weight: normal;
  }
  .card-main {
    display: flex;
    flex-wrap: wrap;
    max-height: 540px;
    overflow-y: scroll;
    flex-direction: column;
    background: white;
  }
  .card-main-sec {
    display: flex;
    flex-wrap: wrap;
    max-height: 540px;
    overflow-y: scroll;
  }
  .card-pers {
    padding-top: 10px;
    padding-top: 10px;
  }
  .card-doc {
    padding-bottom: 10px;
    border-bottom: 2px solid #131c4c4a;
    height: 40px;
    padding-bottom: 5px;
    padding-top: 9px;
  }
  .card-doc-details {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
  }
  .card-new {
    border-bottom: 2px solid #131c4c4a;
  }
  .btn-doc {
    width: 75px;
    height: 27px;
    border: 1px solid #131c4c;
    border-radius: 31px;
    opacity: 1;
    background-color: #131c4c;
    color: #ffffff;
    padding: 4px;
  }
  .card-iot {
    color: #636571;
    padding-bottom: 10px;
  }
  .doc-loc {
    color: #636571;
    opacity: 1;
    font-size: 12px;
  }
  .client-head {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #131c4c;
    opacity: 1;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .dflex {
    display: flex;
    gap: 10px;
  }
  .dflex1 {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  .client {
    letter-spacing: 0px;
    font-size: 20px;
    font-weight: normal;
    color: #131c4c;
    opacity: 1;
  }
  .Card:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border: solid 2px #131c4c;
  }

  .Card.active {
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border: solid 2px #131c4c;
  }
  .Card h3 {
    margin: 0 0 10px;
  }

  .Card p {
    margin: 0;
  }

  input,
  textarea {
    width: 100%;
    margin-bottom: 15px;
    padding: 25px 10px 10px 10px;
    height: 30px;
    border-radius: 4px;
  }

  button {
    /* color: white;
    padding: 10px 20px;
    border: none; */
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
}
.headed {
  width: auto;
  background: black;
  display: block;
  height: 60px;
  margin-left: 20px;
  margin-right: 10px;
  background: #131c4c 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  display: flex;
  flex-direction: row;
}
.subhead {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  padding-left: 20px;
  padding-top: 20px;
}
.pSig {
  width: 200px;
  padding-left: 20px;
}
.informa {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #2c43bf;
  border-radius: 11px;
  width: auto;
  height: 125px;
  margin-left: 20px;
  margin-right: 10px;
  display: flex;
  margin-top: 10px;
  padding: 10px;
}
.sub-info {
  padding: 5px;
  display: flex;
  gap: 10px;
}
.btn-overlay {
  top: 35%;
  left: 25%;
  position: absolute;
  border: 1px solid #131c4c !important;
  border-radius: 31px;
  background: transparent;
  color: #131c4c !important;
}
.map1 {
  width: 100%;
  height: 530px;
  border-radius: 20px;
  opacity: 0.5;
}
.map2 {
  width: 100%;
  height: 530px;
  border-radius: 20px;
}
.sub-infou {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 20px;
}
.sub-infoi {
  padding: 5px;
  padding-left: 20px;
}
.sub-info-middle {
  border-right: 2px dotted #636571;
}
.status-progress {
  width: 85px;
  height: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d0be39;
  border-radius: 5px;
  color: #d0be39;

  padding: 3px;
  display: flex;
  font-size: 10px;
}
.status-done {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #61b768;
  border-radius: 5px;
  width: 40px;
  height: 20px;
  color: #61b768;
  padding: 3px;
  display: flex;
  font-size: 10px;
}
.status-missing {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e67441;
  border-radius: 5px;
  width: 85px;
  height: 20px;
  color: #e67441;
  padding: 3px;
  display: flex;
  font-size: 10px;
}
